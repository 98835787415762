import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Container,
  Segment,
  Divider,
  Form,
  Icon,
  Button,
  Header,
  Loader,
  Responsive,
} from "semantic-ui-react";
import TestataPagina from "../common/TestataPagina";
import FormCliente from "../common/FormCliente";
import TestoPrivacy from "../common/TestoPrivacy";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as appActions from "../../redux/actions/appActions";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as emailUtils from "../../utils/emailUtils";
import * as checkInApi from "../../api/checkinApi";
import { validaCodiceFiscale } from "../../utils/cfUtils";
import InfoWeb from "../common/InfoWeb";

const CheckInMain = ({
  datiScuola,
  codScuola,
  loadSchoolData,
  appData,
  lang,
  setSlug,
  setLang,
  clearAppData,
  ...props
}) => {
  const history = useHistory();
  const [listaNazioni, setListaNazioni] = useState([]);
  const [listaNazioniDial, setListaNazioniDial] = useState([]);
  const [trFinalyzing, setTrFinalyzing] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [privacy2, setPrivacy2] = useState(false);
  const [privacy3, setPrivacy3] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showPrivacy2, setShowPrivacy2] = useState(false);
  const [showPrivacy3, setShowPrivacy3] = useState(false);
  const [listaLivelli, setListaLivelli] = useState([]);
  const [listaAnni, setListaAnni] = useState([]);
  const [numAllievi, setNumAllievi] = useState(3);
  const [datiCliente, setDatiCliente] = useState(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return {
        nome: "TEST NOME",
        cognome: "TEST COGNOME",
        nazione: "IT",
        prefisso: "+39",
        citta: "TEST CITTA",
        cap: "38037",
        indirizzo: "VIA TEST, 28",
        telefono: "300000000",
        email: "juricapovilla@gmail.com",
        cf: "",
        dataNascita: "",
        luogoNascita: "",
      };
    } else {
      return {
        nome: "",
        cognome: "",
        nazione: appData.lang.toLowerCase() === "it" ? "IT" : "",
        prefisso: appData.lang.toLowerCase() === "it" ? "+39" : "",
        citta: "",
        cap: "",
        indirizzo: "",
        telefono: "",
        email: "",
        cf: "",
        dataNascita: "",
        luogoNascita: "",
      };
    }
  });
  const [datiAllievi, setDatiAllievi] = useState(
    Array(20).fill({ nome: "", cognome: "", livello: -1, anno: -1 })
  );
  const [errorsAllievi, setErrorsAllievi] = useState(() => {
    const errorsArray = [];
    for (let i = 0; i < 20; i++) {
      errorsArray.push({});
    }
    return errorsArray;
  });
  const [errors, setErrors] = useState({
    codice: false,
    nome: false,
    cognome: false,
    nazione: false,
    citta: false,
    cap: false,
    indirizzo: false,
    email: false,
    telefono: false,
    causale: false,
    importo: false,
    privacy: false,
    condizioniVendita: false,
    cf: false,
    luogoNascita: false,
    dataNascita: false,
  });

  //////////////////////////////////////////
  //REFS
  const userDataRef = useRef();

  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  // useEffect(() => {
  //   i18n.emit("languageChanged");
  // }, [datiScuola.customTranslationNamespace]);
  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  useEffect(() => {
    if (codScuola !== appData.slug || lang !== appData.lang) {
      clearAppData();
      loadSchoolData(codScuola, lang);
      setSlug(codScuola);
      setLang(lang);
    }
  }, []);

  useEffect(() => {
    if (datiScuola.hasOwnProperty("listaNazioni") && datiScuola !== undefined) {
      setListaNazioni(
        datiScuola.listaNazioni.map((n) => {
          return { key: n.iso, value: n.iso, text: n.nazione };
        })
      );

      setListaNazioniDial(
        [...datiScuola.listaNazioni]
          .sort((a, b) => {
            const nameA = a.iso.toUpperCase(); // ignore upper and lowercase
            const nameB = b.iso.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
          .filter((n) => {
            return n.dialCode?.length >= 2;
          })
          .map((n) => {
            return {
              key: n.iso,
              value: n.dialCode,
              text: n.iso + " " + n.dialCode,
              flag: n.iso.toLowerCase(),
            };
          })
      );

      const livelliTmp = datiScuola.tLivelli.map((n) => {
        return { key: n.id, value: n.id, text: n.livello };
      });
      livelliTmp.push({ key: -1, value: -1, text: "N/D" });
      setListaLivelli(livelliTmp);

      const currentYear = new Date().getFullYear();
      let listaAnniTmp = [{ key: -1, value: -1, text: "" }];
      listaAnniTmp = listaAnniTmp.concat(
        [...Array(100).keys()]
          .filter((x) => currentYear - x < currentYear - 3)
          .map((x, i) => {
            return {
              key: currentYear - i,
              value: currentYear - i,
              text: currentYear - i,
            };
          })
      );

      setListaAnni(listaAnniTmp);
    }
  }, [datiScuola]);

  function checkDati() {
    let checkOk = true;
    var newErrors = { ...errors };
    if (datiCliente.nome.length < 2 || datiCliente.nome > 50) {
      newErrors.nome = true;
      checkOk = false;
    }
    if (datiCliente.cognome.length < 2 || datiCliente.cognome > 50) {
      newErrors.cognome = true;
      checkOk = false;
    }

    if (datiCliente.nazione.length !== 2) {
      newErrors.nazione = true;
      checkOk = false;
    }

    if (datiCliente.citta.length < 2 || datiCliente.citta.length > 50) {
      newErrors.citta = true;
      checkOk = false;
    }

    if (datiCliente.indirizzo.length < 2 || datiCliente.indirizzo.length > 50) {
      newErrors.indirizzo = true;
      checkOk = false;
    }

    if (datiCliente.cap === null) datiCliente.cap = "";
    if (datiCliente.cap.length < 3 || datiCliente.cap.length > 15) {
      newErrors.cap = true;
      checkOk = false;
    }

    if (
      datiCliente.email.length < 2 ||
      datiCliente.email.length > 50 ||
      !emailUtils.ValidateEmail(datiCliente.email)
    ) {
      newErrors.email = true;
      checkOk = false;
    }

    if (datiCliente.telefono.length < 5 || datiCliente.telefono.length > 15) {
      newErrors.telefono = true;
      checkOk = false;
    }

    if (privacy === false) {
      newErrors.privacy = true;
      checkOk = false;
    }

    //Verifico se ho attivato il Codice Fiscale obbligatorio
    if (datiScuola.usaCodiceFiscale) {
      if (datiCliente.nazione.length > 0) {
        if (datiCliente.nazione === "IT") {
          newErrors.cf = false;
          if (!validaCodiceFiscale(datiCliente.cf)) {
            newErrors.cf = true;
            checkOk = false;
          }
        } else {
          newErrors.luogoNascita = false;
          newErrors.dataNascita = false;
          if (datiCliente.luogoNascita.length < 3) {
            newErrors.luogoNascita = true;
            checkOk = false;
          }
          if (
            datiCliente.dataNascita === "" ||
            datiCliente.dataNascita === null ||
            datiCliente.dataNascita === undefined
          ) {
            newErrors.dataNascita = true;
            checkOk = false;
          }
        }
      }
    }

    //Verifico gli allievi
    for (let i = 0; i < numAllievi; i++) {
      if (datiAllievi[i].nome === "" && datiAllievi[i].cognome === "") continue;

      if (datiAllievi[i].nome.length < 2 || datiAllievi[i].nome > 50) {
        errorsAllievi[i].nome = true;
        checkOk = false;
      }
      if (datiAllievi[i].cognome.length < 2 || datiAllievi[i].cognome > 50) {
        errorsAllievi[i].cognome = true;
        checkOk = false;
      }

      if (datiAllievi[i].cognome.length > 2 && datiAllievi[i].nome.length > 2) {
        if (datiAllievi[i].livello === -1) {
          errorsAllievi[i].livello = true;
          checkOk = false;
        }
        if (datiAllievi[i].anno < 1900) {
          errorsAllievi[i].anno = true;
          checkOk = false;
        }
      }

      setErrors(newErrors);

      return checkOk;
    }
  }

  //Helpers Functions
  function handleFieldChange(ev, data) {
    if (data.name === "cap") {
      if (data.value.length > 6) data.value = data.value.substring(0, 6);
    }

    if (data.name === "telefono") {
      const re = /^[0-9\b]+$/;
      if (data.value !== "" && !re.test(data.value)) {
        data.value = datiCliente.telefono;
      }
    }
    if (data.name === "nazione") {
      const nation = listaNazioniDial.find((x) => x.key === data.value);
      datiCliente.prefisso = nation.value;
    }

    let datiTmp = { ...datiCliente };
    datiTmp[data.name] = data.value;
    setErrors({ ...errors, [data.name]: false });
    setDatiCliente(datiTmp);
  }

  const handleAllievoFieldChange = (data, idx) => {
    let datiTmp = JSON.parse(JSON.stringify(datiAllievi)); // Deep copy
    if (data.name === "nome") {
      datiTmp[idx].nome = data.value;
    }
    if (data.name === "cognome") {
      datiTmp[idx].cognome = data.value;
    }

    if (data.name === "livello") {
      datiTmp[idx].livello = data.value;
    }

    if (data.name === "annoNascita") {
      datiTmp[idx].anno = data.value;
    }
    setDatiAllievi(datiTmp);

    const errorsTmp = [...errorsAllievi];
    if (data.name === "annoNascita") {
      errorsTmp[idx] = { ...errorsAllievi[idx], anno: false };
    } else {
      errorsTmp[idx] = { ...errorsAllievi[idx], [data.name]: false };
    }

    setErrorsAllievi(errorsTmp);
  };

  function handlePrivacyChange(ev, data) {
    setPrivacy(data.checked);
    setErrors({ ...errors, privacy: !data.checked });
  }

  function handlePrivacy2Change(ev, data) {
    setDatiCliente({ ...datiCliente, privacy2: data.checked });
    setPrivacy2(data.checked);
  }
  function handlePrivacy3Change(ev, data) {
    setDatiCliente({ ...datiCliente, privacy3: data.checked });
    setPrivacy3(data.checked);
  }

  async function handleEffettuaCheckin(ev, data) {
    if (!checkDati()) {
      userDataRef.current.scrollIntoView(true, {
        behavior: "smooth",
        block: "start",
      });
      return;
    }
    setTrFinalyzing(true);

    //correggo i livelli
    for (let i = 0; i < datiAllievi.length; i++) {
      if (parseInt(datiAllievi[i].livello) !== -1) {
        if (datiAllievi[i].cognome !== "") {
          datiAllievi[i].livello = datiScuola.tLivelli.filter(
            (x) => x.id === parseInt(datiAllievi[i].livello)
          )[0].idLivelloIso;
        }
      }
    }

    datiCliente.allievi = datiAllievi.filter(
      (x) => x.nome !== "" && x.cognome !== ""
    );

    var result = await checkInApi.CheckIn({
      ...datiCliente,
      idScuola: datiScuola.id,
      codiceScuola: codScuola,
    });

    if (result === null) {
      alert("problemi nell'esecuzione del check-in, riprovare più tardi");
      setTrFinalyzing(false);
      return;
    }

    if (result.ok === false) {
      alert("problemi nell'esecuzione del check-in, riprovare più tardi");
      setTrFinalyzing(false);
      return;
    }

    //console.log(await result.json());
    history.push(`/${appData.lang}/${appData.slug}/Checkin/Ok`);

    setTrFinalyzing(false);
  }

  if (props.loading || datiScuola === undefined || trFinalyzing) {
    return <Loader size="huge" active />;
  } else {
    return (
      <Container>
        <div className="head-offset"></div>
        <TestataPagina
          titolo="ONLINE CHECK-IN"
          icon="address book"
          //sottoTitolo={t("Acquista_qui_buono")}
          //sottoTitolo="Velocizza le tue operazioni al banco effettuando il check-in online"
        />
        <Segment
          className="padding_5_lr contenitore-grande"
          style={{ marginBottom: "30px" }}
        >
          <div ref={userDataRef}></div>
          <Header as="h3" className="skiwork-titolo">
            <Header.Content className="scelta-data">
              DATI CLIENTE - INTESTATARIO
            </Header.Content>
          </Header>

          <FormCliente
            datiCliente={datiCliente}
            datiScuola={datiScuola}
            handleFieldChange={handleFieldChange}
            listaNazioni={listaNazioni}
            listaNazioniDial={listaNazioniDial}
            errors={errors}
            t={t}
          />
          <Header as="h3" className="skiwork-titolo">
            <Header.Content className="scelta-data">
              DATI ALLIEVI
            </Header.Content>
          </Header>
          <Form>
            {[...Array(numAllievi)].map((x, i) => (
              <>
                <Responsive maxWidth={768}>
                  <Divider />
                  <div style={{ textAlign: "center" }}>Allievo {i + 1}</div>
                </Responsive>
                <Form.Group>
                  <Form.Input
                    label={t("nome")}
                    placeholder={t("nome")}
                    fluid
                    name="nome"
                    onChange={(ev, data) => handleAllievoFieldChange(data, i)}
                    width={8}
                    value={datiAllievi[i].nome}
                    error={
                      errorsAllievi[i].nome
                        ? { content: t("nome_error") }
                        : null
                    }
                  />
                  <Form.Input
                    label={t("cognome")}
                    placeholder={t("cognome")}
                    fluid
                    name="cognome"
                    onChange={(ev, data) => handleAllievoFieldChange(data, i)}
                    width={8}
                    value={datiAllievi[i].cognome}
                    error={
                      errorsAllievi[i].cognome
                        ? { content: t("cognome_error") }
                        : null
                    }
                  />
                  <Form.Select
                    label={t("anno_nascita")}
                    placeholder={t("anno_nascita")}
                    fluid
                    name="annoNascita"
                    options={listaAnni}
                    onChange={(ev, data) => handleAllievoFieldChange(data, i)}
                    width={8}
                    value={datiAllievi[i].anno}
                    error={errorsAllievi[i].anno ? "!!!" : null}
                  />
                  <Form.Select
                    label={t("livello")}
                    placeholder={t("livello")}
                    fluid
                    name="livello"
                    options={listaLivelli}
                    onChange={(ev, data) => handleAllievoFieldChange(data, i)}
                    width={8}
                    value={datiAllievi[i].livello}
                    error={errorsAllievi[i].livello ? "!!!" : null}
                  />
                </Form.Group>
                <Responsive
                  maxWidth={768}
                  style={{ marginBottom: "14px" }}
                ></Responsive>
              </>
            ))}
            {numAllievi < 20 && (
              <div style={{ width: "100%", textAlign: "right" }}>
                <Button
                  primary
                  onClick={() => {
                    setNumAllievi(numAllievi + 1);
                  }}
                >
                  <Icon name="plus"></Icon>
                  Altro allievo
                </Button>
              </div>
            )}
          </Form>
          <Form>
            <Divider />
            <div
              style={{ cursor: "pointer", marginBottom: "14px" }}
              onClick={() => {
                setShowPrivacy(true);
              }}
            >
              <a href>
                {t("informativa")} <Icon name="external"></Icon>
              </a>
            </div>
            <Form.Checkbox
              label={t("acconsento_privacy")}
              checked={privacy}
              onChange={handlePrivacyChange}
              error={
                errors.privacy
                  ? {
                      content: t("necessario_accettare_privacy"),
                      pointing: "left",
                    }
                  : null
              }
            ></Form.Checkbox>
            {datiScuola.richiediPrivacy2 && (
              <>
                <Form.Checkbox
                  label={datiScuola.titoloPrivacy2}
                  checked={privacy2}
                  name="privacy2"
                  onChange={handlePrivacy2Change}
                ></Form.Checkbox>
                {(datiScuola.testoPrivacy2?.length ?? 0) > 0 && (
                  <p
                    style={{
                      marginLeft: "32px",
                      marginTop: "-14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowPrivacy2(true);
                    }}
                  >
                    <a href>
                      Maggiori informazioni <Icon name="external"></Icon>
                    </a>
                  </p>
                )}
              </>
            )}
            {datiScuola.richiediPrivacy3 && (
              <>
                <Form.Checkbox
                  label={datiScuola.titoloPrivacy3}
                  checked={privacy3}
                  name="privacy3"
                  onChange={handlePrivacy3Change}
                ></Form.Checkbox>
                {(datiScuola.testoPrivacy3?.length ?? 0) > 0 && (
                  <p
                    style={{
                      marginLeft: "32px",
                      marginTop: "-14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowPrivacy3(true);
                    }}
                  >
                    <a href>
                      Maggiori informazioni <Icon name="external"></Icon>
                    </a>
                  </p>
                )}
              </>
            )}
            {datiScuola.infoWeb !== undefined && (
              <div
                style={{
                  //marginLeft: windowSize.width > 600 ? "1em" : "2px",
                  marginTop: "2em",
                }}
              >
                <InfoWeb
                  testi={datiScuola.infoWeb.filter((x) => x.sezione === 2)}
                  sezione={2}
                />
              </div>
            )}

            <p style={{ textAlign: "center" }}>
              <Button size="huge" primary onClick={handleEffettuaCheckin}>
                CHECK-IN
                {/* {t("effettua_pagamento")} */}
              </Button>
            </p>
          </Form>
          <TestoPrivacy
            isOpen={showPrivacy}
            onClose={() => {
              setShowPrivacy(false);
            }}
            t={t}
          />
          <TestoPrivacy
            isOpen={showPrivacy2}
            onClose={() => {
              setShowPrivacy2(false);
            }}
            t={t}
            numRichiesta={2}
          />
          <TestoPrivacy
            isOpen={showPrivacy3}
            onClose={() => {
              setShowPrivacy3(false);
            }}
            t={t}
            numRichiesta={3}
          />
        </Segment>
      </Container>
    );
  }
};

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadSchoolData: scuolaActions.loadSchoolData,
  setSlug: appActions.setSlug,
  setLang: appActions.setLang,
  clearAppData: appActions.clearAppData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckInMain);
